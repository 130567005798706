@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
:root {
    --blue500: #2E61DE;
    --blue400: #537DEC;
    --gray900:  #18191D;
    --gray800:#2F3137;
    --gray700: #464A51;
    --gray600: #5C626A;
    --gray300:#A3ABB3;
    --gray200:#BCC4CA;
    --gray50: #F2F5F8;
    --maple500:#DE2E37;
    --maple50:#FFF5F5;
    --green700:#0EAD30;
    --green50:#F5FFF6;
    --white: #ffffff;
    --headings: #020203;
    --textNormal: var(--gray700);
    --textDimmed: var(--gray600);

    /* old kounta colours below */
    --charcoal10: var(--gray50);
    --charcoal20: var(--gray50);
    --charcoal40: var(--gray50);
    --charcoal50: var(--gray200);
    --charcoal70: var(--gray600);
    --charcoal80: var(--gray700);
    --charcoal90:  var(--gray800);
    --orange70: var(--blue500);
    --orange90:  var(--gray700);
    --green70: var(--green700);
    --red80: var(--maple500);
}

html, body {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    min-height: 100%;
    height: 100%;
}

#root {
    min-height: 100%;
    height: 100%;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

body {
    background: #ffffff;
    background: var(--white);
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    font-size: 62.5%;
    line-height: 1.4;
}

.container {
    max-width: 420px;
    margin: 36px auto -198px auto;
    padding-left: 32px;
    padding-right: 32px;
    min-height: 100%;
    height: auto !important;
}

.container:after {
    content: "";
    display: block;
    height: 198px;
}

header {
    text-align: center;
    margin: auto;
    position: relative;
    z-index: 3;
}
header.light > *{
 color:#ffffff;
 color:var(--white);
}
svg {
    margin: 24px auto;
    text-align: center;
}

h1 {
    font-weight: normal;
    color: #464A51;
    color: var(--gray700);
    text-align: center;
    margin-bottom: 24px;
    letter-spacing: 4px;
    font-size: 18px;
}

h2 {
    color: #020203;
    color: var(--headings);
    text-align: center;
    font-weight: bold;
    font-size: 2.8em;
    margin-bottom: 36px;
    line-height: 1.28;
}

h3 {
    color: #020203;
    color: var(--headings);
    text-align: center;
    font-weight: normal;
    font-size: 1.8em;
    margin-bottom: 12px;
}

h4 {
    color: #020203;
    color: var(--headings);
    text-align: center;
    font-weight: bold;
    font-size: 2.4em;
    margin-top: 12px;
    margin-bottom: 48px;
}

p {
    text-align: center;
    color: #464A51;
    color: var(--textNormal);
    font-size: 1.6em;
    margin-bottom: 24px;
}

.guest-count-title {
    margin-bottom: 12px;
}

.guest-count {
    color: #464A51;
    color: var(--textNormal);
    text-align: center;
    font-weight: bold;
    font-size: 2.4em;
    margin-top: 12px;
    margin-bottom: 48px;
}

p.footnote a,
p.footnote {
    text-align: left;
    color: #5C626A;
    color: var(--textDimmed);
    font-size: 14px;
    font-style: italic;
    margin-bottom: 24px;
    text-decoration: none;
    line-height: 21px;

}
p.footnote .link {
    color: #2E61DE;
    color: var(--blue500);
    text-decoration: underline;
}


.success-msg {
    width: 72%;
    margin: 48px auto 60px auto;
    text-align: center;
}

.covid-form {
    max-width: 100%;
    margin: 48px auto;
}

.input-wrap {
    position: relative;
    margin-top: 24px;
}

input,
button {
    display: block;
    background-color: #F2F5F8;
    background-color: var(--charcoal10);
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #BCC4CA;
    border-bottom: 1px solid var(--charcoal50);
    padding: 10px 15px;
    margin-top: 6px;
    margin-bottom: 20px;
    font-size: 1.4em;
    height: 46px;
    transition: all 0.3s;
    outline: none;
}
input {
    border-radius: 4px 4px 0 0 ;
}
input.success {
    border-bottom: 1px solid #0EAD30;
    border-bottom: 1px solid var(--green70);
}

input::-webkit-input-placeholder {
    color: #F2F5F8;
    color: var(--charcoal40);
}

input::-ms-input-placeholder {
    color: #F2F5F8;
    color: var(--charcoal40);
}

input::placeholder {
    color: #F2F5F8;
    color: var(--charcoal40);
}

label,
.label-copy{
    line-height: 1.4;
    text-align: left;
    display: block;
    margin-bottom: 6px;
    color: #5C626A;
    color: var(--charcoal70);
    font-size: 1.2em;
    font-weight: normal;
}

input.error {
    border-bottom: 1px solid #DE2E37;
    border-bottom: 1px solid var(--red80);
}

span.error-msg::after {
    display: block;
    content: "\26A0";
    color: #DE2E37;
    color: var(--red80);
    position: absolute;
    top: -38px;
    right: 12px;
    font-size: 2em;
}

span.error-msg {
    color: #DE2E37;
    color: var(--red80);
    font-size: 1em;
    font-weight: normal;
    position: absolute;
    bottom: -16px;
    width: 100%;
}

p.error-msg {
    color: #DE2E37;
    color: var(--red80);
    font-size: 1.4em;
    font-weight: normal;
}

input[type="submit"],
a.btn-blue {
    background: #2E61DE;
    background: var(--orange70);
    color: #ffffff;
    color: var(--white);
    text-transform: capitalize;
    border: none;
    margin: 32px auto 24px auto;
    font-size: 1.6em;
    font-weight: normal;
    max-width: 180px;
    transition: 0.3s all;
    border-radius: 4px;
}
a.btn-blue {
    margin-top: 24px;
    padding: 12px 15px;
    height: 46px;
    text-decoration: none;
    display: inline-block;
}
button.btn-add-guest {
    background: #ffffff;
    background: var(--white);
    color: #2E61DE;
    color: var(--blue500);
    text-transform: capitalize;
    border: none;
    margin: 24px auto 48px auto;
    font-size: 1.6em;
    font-weight: normal;
    max-width: 200px;
    transition: 0.3s all;
    display: flex;
    align-items: center;
}

button.btn-add-guest:hover {
    background: #ffffff;
    background: var(--white);
    color: #464A51;
    color: var(--charcoal80);
}

button.btn-add-guest svg {
    margin: auto;
}

button.btn-add-guest:hover svg {
    width: 20px;
    height: 20px;
    fill: #464A51;
    fill: var(--orange90);
}

button.btn-add-guest:hover svg path {
    fill: #464A51;
    fill: var(--orange90);
}

button.btn-add-guest:active {
    background: #ffffff;
    background: var(--white);
    color: #464A51;
    color: var(--orange90);
}

input[type="submit"]:hover {
    background-color: #464A51;
    background-color: var(--orange90);
}

input[type="submit"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
}

input:disabled {
    opacity: 0.4;
}

button[type="submit"],
input[type="button"],
input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: none;
}

footer {
    padding: 24px 24px 36px 24px;
    background-color: #2F3137;
    background-color: var(--gray800);
    color: #ffffff;
    color: var(--white);
    text-align: center;
    position:relative;
    z-index: 2;
}

footer .logo {
    margin: 0 auto 40px auto;
    text-align: center;
}

footer p {
    font-size: 1.4em;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    color: var(--white);
    margin-bottom: 0;
}
footer p  a{
    font-weight: bold;
    text-decoration: none;
    color: #ffffff;
    color: var(--white);
    margin-bottom: 0;
}
.input-wrap.checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox input {
    margin: 0 12px 0 0;
    padding: 0;
    float: left;
    height:28px;
    width: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 2px solid #BCC4CA;
    border: 2px solid var(--gray200);
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    background-color: #ffffff;
    background-color: var(--white);
    cursor: pointer;
}
.checkbox .label {
   margin-top: 6px;
   display: block;
}
.checkbox input:checked {
    border: 2px solid #5C626A;
    border: 2px solid var(--gray600);
    background-color: #ffffff;
    background-color: var(--white);
}

.checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked ~ .checkmark::after {
    display: block;
}

.checkbox input:checked + .checkmark::after {
    display: block;
    text-align: center;
    left: 11px;
    top: 2px;
    width: 9px;
    height: 18px;
    border: solid #5C626A;
    border: solid var(--charcoal70);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.checkbox input:active {
    border: 2px solid #5C626A;
    border: 2px solid var(--charcoal70);
}
/*.checkbox.small  {*/
/*    width:74%;*/
/*    margin:auto;*/
/*}*/
/*.checkbox.small label  {*/
/* line-height: 22px;*/
/*}*/
/*.checkbox.small input {*/
/*!* border-radius: 50%; *!*/
/*width:24px;*/
/*height:24px;*/
/*}*/
/*.checkbox.small input + .checkmark::after {*/
/*    left: 8px;*/
/*    top: 2px;*/
/*    width: 8px;*/
/*    height: 16px;*/
/*}*/
.loading-kounta {
    margin: auto;
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    min-height: 20vh;
}

#loading-k {
    fill: #2E61DE;
    fill: var(--orange70);
    -webkit-animation-name: load-color-change;
            animation-name: load-color-change;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    transition: all .3s;
}

@-webkit-keyframes load-color-change {
    0% {
        fill: #DE2E37;
        fill: var(--maple500);
    }
    25% {
        fill: #BCC4CA;
        fill: var(--gray200);
    }
    50% {
        fill: #FFF5F5;
        fill: var(--maple50);
    }
    75% {
        fill: #5C626A;
        fill: var(--gray600);
    }
    100% {
        fill: #DE2E37;
        fill: var(--maple500);
    }
}

@keyframes load-color-change {
    0% {
        fill: #DE2E37;
        fill: var(--maple500);
    }
    25% {
        fill: #BCC4CA;
        fill: var(--gray200);
    }
    50% {
        fill: #FFF5F5;
        fill: var(--maple50);
    }
    75% {
        fill: #5C626A;
        fill: var(--gray600);
    }
    100% {
        fill: #DE2E37;
        fill: var(--maple500);
    }
}

.landing-page {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background: url(/static/media/hero@2x.3282ce6c.jpg) no-repeat fixed center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
}
.landing-page #overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
}
.landing-page .inner {
    padding:24px;
    text-align: left;
    color: #ffffff;
    color: var(--white);
    max-width:400px;
    margin: auto;
    height: 400px;
    z-index: 2;
}
.landing-page .inner > * {
    color: #ffffff;
    color: var(--white);
    text-align: left;
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin-right: 12px;
    margin-top: 2px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F2F5F8;
    background-color: var(--gray50);
    transition: .4s;
}

.slider::before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 2px;
    background-color: #A3ABB3;
    background-color: var(--gray300);;
    transition: .4s;
}
.slider::after {
    content: "";
    position: absolute;
    display: block;
    text-align: center;
    left: 11px;
    top: 6px;
    width: 5px;
    height: 11px;
    border: solid #ffffff;
    border: solid var(--white);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: .4s;
}
input:checked + .slider {
    background-color: #F2F5F8;
    background-color: var(--gray50);
}
input:checked + .slider:before {
    background-color: #2E61DE;
    background-color: var(--blue500);
}

input:focus + .slider {
    box-shadow: 0 0 1px #F2F5F8;
    box-shadow: 0 0 1px var(--gray50);;
}

input:checked + .slider:before {
    transform: translateX(26px);
}
input:checked + .slider:after {
    transform: rotate(45deg) translateX(19px) translateY(-19px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

